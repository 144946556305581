<script>
  import netlifyIdentity from 'netlify-identity-widget'
  import { user, redirectURL } from './store.js'

  netlifyIdentity.init()

  $: isLoggedIn = !!$user
  $: username = $user !== null ? $user.username : ' there!'

  function handleUserAction(action) {
    if (action === 'login') {
      netlifyIdentity.open(action)
      netlifyIdentity.on('login', u => {
        user.login(u)
        netlifyIdentity.close()
        if ($redirectURL !== '') {
          navigate($redirectURL)
          redirectURL.clearRedirectURL()
        }
      })
    } else if (action === 'logout') {
      navigate('/')
      user.logout()
      netlifyIdentity.logout()
    }
  }
</script>

<style>
  :global(body) {
    margin: 0;
    padding: 0;
    color: #2c3e50;
  }
  :global(a, a:visited) {
    color: #ff3e00;
  }
  main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h1 {
    font-size: 1.2rem;
  }
  img {
    display: block;
    width: 200px;
    max-width: 100%;
    height: auto;
    order: -1;
  }
  ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  ul > * + * {
    margin-left: 1rem;
  }
  .center {
    text-align: center;
  }
</style>

<main>

  {#if isLoggedIn}
  <iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" src="https://pkit.in/test/_/index.php" style="height:100vh; width: 100vw;" frameborder="0"></iframe>
  {:else}
    <div class="center">
      <p>You are not logged in.</p>
      <div>
        <button on:click={() => handleUserAction('login')}>Log In</button>
      </div>
    </div>
  {/if}
</main>
